@import '../globalStyle.scss';

.portfolioSection {
  background: #f2f2f2;
  @include triangle-header(#f2f2f2);
}

.content {
  position: relative;
  z-index: 2;
}

.title {
  @include flex-center;
}

.projectsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.mobileInfo {
  position: absolute;
  font-size: 20px;
  top: 16%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
