@import '../globalStyle';

.landingPage {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
  font-family: $titleFont;
  font-weight: bold;
  position: relative;

  h1 {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    height: 1px;
    text-indent: -9999px;

    span {
      display: block;
      text-indent: -9999px;
    }

    @include targetIE {
      text-indent: 0;
      height: auto;

      span {
        text-indent: 0;
      }
    }
  }

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #3c58f4;
    background-image: radial-gradient(
        circle at 82% 60%,
        rgba(59, 59, 59, 0.06) 0%,
        rgba(59, 59, 59, 0.06) 69%,
        transparent 69%,
        transparent 100%
      ),
      radial-gradient(
        circle at 36% 0%,
        rgba(185, 185, 185, 0.06) 0%,
        rgba(185, 185, 185, 0.06) 59%,
        transparent 59%,
        transparent 100%
      ),
      radial-gradient(
        circle at 58% 82%,
        rgba(183, 183, 183, 0.06) 0%,
        rgba(183, 183, 183, 0.06) 17%,
        transparent 17%,
        transparent 100%
      ),
      radial-gradient(
        circle at 71% 32%,
        rgba(19, 19, 19, 0.06) 0%,
        rgba(19, 19, 19, 0.06) 40%,
        transparent 40%,
        transparent 100%
      ),
      radial-gradient(
        circle at 77% 5%,
        rgba(31, 31, 31, 0.06) 0%,
        rgba(31, 31, 31, 0.06) 52%,
        transparent 52%,
        transparent 100%
      ),
      radial-gradient(
        circle at 96% 80%,
        rgba(11, 11, 11, 0.06) 0%,
        rgba(11, 11, 11, 0.06) 73%,
        transparent 73%,
        transparent 100%
      ),
      radial-gradient(
        circle at 91% 59%,
        rgba(252, 252, 252, 0.06) 0%,
        rgba(252, 252, 252, 0.06) 44%,
        transparent 44%,
        transparent 100%
      ),
      radial-gradient(
        circle at 52% 82%,
        rgba(223, 223, 223, 0.06) 0%,
        rgba(223, 223, 223, 0.06) 87%,
        transparent 87%,
        transparent 100%
      ),
      radial-gradient(
        circle at 84% 89%,
        rgba(160, 160, 160, 0.06) 0%,
        rgba(160, 160, 160, 0.06) 57%,
        transparent 57%,
        transparent 100%
      ),
      linear-gradient(90deg, rgb(46, 75, 248), rgb(166, 255, 237));
  }

  svg {
    will-change: fill;
  }

  path {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    will-change: stroke-dashoffset;
  }
}

.link {
  padding: 0;
  pointer-events: none;
  animation: show 700ms ease forwards 2.3s;
  font-size: 18px;
  margin-top: 25px;
  padding: 10px 18px;
  background: none;
  outline: none;
  border: 2px solid currentColor;
  color: white;
  font-family: $font;
  opacity: 0;
  animation: show 700ms ease forwards 1.3s;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;

  &:hover {
    color: #8cf6fa;
  }
}

.svgTitle {
  margin-bottom: 20px;
  animation: fill 0.8s ease forwards 1s;

  path {
    animation: line-anim 2.3s ease forwards;
  }
}

.svgSubtitle {
  animation: fill 0.8s ease forwards 1.2s;

  path {
    animation: line-anim 2.3s ease forwards 0.8s;
  }
}

@include targetIE {
  .svgTitle,
  .svgSubtitle {
    display: none !important;
  }
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}

@keyframes show {
  to {
    opacity: 1;
    pointer-events: auto;
  }
}

@media (max-width: 700px) {
  .svgTitle,
  .svgSubtitle {
    height: auto;
  }

  .svgTitle {
    width: 310px;
    margin-bottom: 20px;
    max-width: calc(100% - 40vw);
  }

  .svgSubtitle {
    width: 440px;
    max-width: calc(100% - 40px);
  }
}
