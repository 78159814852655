@import '../globalStyle.scss';

.wrapper {
  position: relative;
  height: 260px;
  width: 400px;
  max-width: 100%;
  margin: 0 10px 30px 10px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(4, 23, 23, 0.1), 0 5px 11px rgba(0, 0, 0, 0.06);
  transform: translateY(100px);
  opacity: 0;
  transition: all 1000ms cubic-bezier(0.25, 0.77, 0.46, 0.99);
  transition-property: transform, opacity;
  overflow: hidden;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  cursor: default;
}

:global(.visible) {
  .wrapper {
    transform: translateY(0);
    opacity: 1;
  }
}

.infoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  font-family: $font;
  font-weight: bold;
  font-size: 40px;
  height: 100%;
  width: 100%;
  background: $pink;
  opacity: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: opacity 400ms ease-in-out;
  padding: 0 20px;

  &:hover {
    opacity: 0.96;
  }
}

.title {
  transform: translateY(-50px);
  transition: transform 300ms ease-in-out;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subTitle {
  font-size: 18px;
  font-style: italic;
}

.iconsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  transform: translateY(40px);
  transition: transform 300ms ease-in-out;
}

.icon {
  cursor: pointer;
  height: 65px;
  width: 65px;
  margin: 0px 20px 0px 20px;

  &:hover {
    opacity: 0.8;
  }
}

.moveText {
  transform: translateY(0);
}

.showClass {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 650px) {
  .wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
