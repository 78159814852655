@import '../globalStyle.scss';

.wrapper {
  position: relative;
  font-family: $font;
  background: #5d3445;
  color: #fff;
  padding: 15px 15px 15px 48px;
  margin-top: 10px;
  border-radius: 2px;
  opacity: 0;
  font-size: 13px;
  line-height: 1;
  animation: show-anim 0.8s ease forwards;
  font-weight: 500;
}

.image {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  margin-top: -1px;
  height: 20px;
  width: 20px;
}

.close {
  position: absolute;
  right: 10px;
  font-weight: bold;
  padding: 5px 10px 7px 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;

  &:hover {
    opacity: 0.6;
  }
}

@keyframes show-anim {
  to {
    opacity: 1;
  }
}

@media (max-width: 510px) {
  .wrapper {
    font-size: 14px;
  }
  .image {
    height: 18px;
    width: 18px;
  }
}

@media (max-width: 440px) {
  .wrapper {
    font-size: 12px;
  }
  .image {
    height: 16px;
    width: 16px;
    left: 2px;
  }
  .close {
    font-size: 14px;
    right: 4px;
  }
}
