@import '../globalStyle.scss';

.input {
  font-size: 16px;
  margin-bottom: 5px;
  border-radius: 2px;
  appearance: none;
  padding: 10px 12px;
  background-color: #1a3a50;
  outline: none;
  border: none;
  color: white;
  font-family: $font;
  font-weight: 500;
  appearance: none;
  -webkit-appearance: none;

  @include placeholder {
    color: rgba(#fff, 0.43);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: #1c3d53;
  }

  &:focus {
    background-color: #234861;
  }
}

textarea {
  min-height: 100px;
  resize: none;
}

.formWrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 500px;
  flex-grow: 0;
  flex-shrink: 0;
  background: $blue;
  margin-bottom: 40px;
}

.wrapper {
  background: $blue;
  position: relative;
  z-index: 1;
  @include triangle-header($blue);
}

.contentWrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.content {
  display: flex;
  justify-content: center;
}

.title {
  @include flex-center;
  color: white;
}

.titleText {
  color: white;
}

.submit {
  cursor: pointer;
  background: none;
  outline: none;
  color: white;
  border: 2px solid white;
  font-size: 16px;
  padding: 8px 20px 10px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 45px;
  line-height: 1;
  width: 100%;
  max-width: 200px;
  appearance: none;
  -webkit-appearance: none;
  transition: color 200ms ease-in-out, border 200ms ease-in-out;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 30px;

  &:hover {
    color: $pink;
    border: 2px solid $pink;
  }
}

.linksWrapper {
  padding-left: 40px;
  flex-grow: 1;
  width: 100%;

  h3 {
    color: $pink;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 2px dashed #234861;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    &:before {
      content: '\00BB';
      margin-right: 8px;
      color: #234861;
      position: relative;
      top: -2px;
    }
  }
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contactLink {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 1;
  font-weight: 600;
  font-size: 18px;
  padding: 9px 18px 9px 13px;
  border-radius: 3px;
  margin-left: -13px;

  path {
    fill: #fff;
  }

  &:hover {
    background-color: rgba(#fff, 0.04);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.contactIcon {
  margin-right: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  top: 1px;

  svg {
    width: 26px;
    height: 26px;
  }
}

@media (max-width: 1150px) {
  .formWrapper {
    width: 390px;
  }
}

@media (max-width: 880px) {
  .content {
    flex-direction: column;
  }

  .formWrapper {
    width: 100%;
    margin-bottom: 60px;
  }

  .linksWrapper {
    padding-left: 0;
  }
}
