.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(242, 242, 242);
  opacity: 0.8;
  z-index: 3;
}

.fixedContainer {
  position: fixed;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.iframeContainer {
  position: relative;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

@media (max-width: 645px) {
  .fixedContainer {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .fixedContainer {
    width: 90%;
  }
}
