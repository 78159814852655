@import '../globalStyle.scss';

a.link {
  text-decoration: none;
  color: white;
  margin-right: 50px;
  transition: color 300ms;

  &:last-child {
    margin-right: 0 !important;
  }

  &:hover {
    color: $pink;
  }
}

nav {
  background: rgba($darkBlue, 0.96);
  backdrop-filter: blur(2px);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-family: $font;
  color: $lightGrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 16px;
  height: 60px;
  z-index: 3;
  text-transform: uppercase;
}

.name {
  font-weight: 700;
}

.links {
  display: flex;
  flex-direction: row;
  font-weight: 500;
}

@media (max-width: 700px) {
  nav {
    padding: 0 30px;
  }

  a.link {
    margin-right: 30px;
  }
}

@media (max-width: 630px) {
  nav {
    font-size: 14px;
    padding: 0 50px 0 50px;
  }
}

@media (max-width: 486px) {
  nav {
    padding-left: 20px;
    padding-right: 20px;
  }

  a.link {
    margin-right: 20px;
  }
}
