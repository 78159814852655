@import '../globalStyle.scss';

.aboutSection {
  padding-top: 60px;
  padding-bottom: 180px;
}

.title {
  text-align: center;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 25px;
  }

  p {
    display: block;
    max-width: 710px;
    margin: 0 auto 15px auto;
    font-weight: 500;
    font-size: 17px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wrapper {
  flex: 4;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.skillsWrapper {
  margin-top: 60px;
  min-width: 270px;
  flex: 1;
}

@media (max-width: 860px) {
  .skillsWrapper {
    margin-top: 50px;
  }
}

@media (max-width: 720px) {
  .wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .skillsWrapper {
    margin-top: 0;
    max-width: none;
    width: 100%;
  }
}
