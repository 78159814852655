// $primary-orange: #FF5851;
$teal: #41b3a3;
$blue: #0c2b42;
$lightBlue: #1c5076;
$lighterBlue: #25699a;
$lightGrey: #f9f9f9;
$pink: #e22866;
$darkBlue: #1b242f;
$titleFont: 'Montserrat', sans-serif;
$font: 'Montserrat', sans-serif;
$fontColor: #616161;
$titleColor: #505050;

@mixin triangle-header($background) {
  position: relative;

  &:before,
  &:after {
    background: $background;
    position: absolute;
    content: '';
    top: -50px;
    width: 50%;
    height: 90%;
    z-index: 1;
  }

  &:before {
    left: 0;
    transform: skewY(6deg);
  }

  &:after {
    right: 0;
    transform: skewY(-6deg);
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin targetIE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
