@import '../globalStyle.scss';

.cartoon {
  width: 84%;
  height: auto;
  margin-bottom: 20px;
}

.wrapper {
  width: 390px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 30px;
  position: relative;
  max-width: 100%;
}

@media (max-width: 960px) {
  .wrapper {
    margin-right: 5px;
  }
}

@media (max-width: 860px) {
  .wrapper {
    width: 320px;
    margin-left: -10px;
    margin-right: 20px;
  }
}

@media (max-width: 720px) {
  .wrapper {
    margin: 0 0 30px 0;
    align-self: center;
  }
}
