@import '../globalStyle.scss';

.wrapper {
  box-sizing: content-box;
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transform-origin: center 40px;
  transition: transform 240ms;
}

.bottom {
  @include flex-center;
  position: absolute;
  background: white;
  transform-origin: bottom;
  width: 100%;
  height: 100%;
  transform: translateZ(40px) rotateX(90deg);
  pointer-events: none;
}

.wrapper:hover {
  .content {
    transform: rotateX(90deg);
  }
}

.front {
  @include flex-center;
  position: absolute;
  border: solid 2px white;
  width: 100%;
  height: 100%;
  transform: translateZ(40px);
  pointer-events: none;
}

.logo {
  width: 100%;
  padding: 5px;
}

.botLogo {
  transform: rotateX(180deg);
}

@media (max-width: 450px) {
  .wrapper:hover {
    .content {
      transform: rotateX(0deg);
    }
  }
}
